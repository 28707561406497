import React, { ReactNode } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Icon from "@mdi/react";
import { ThunkDispatch } from "redux-thunk";
import {
    mdiChevronDown,
    mdiDotsHorizontal,
} from "@mdi/js/commonjs/mdi";
import { mdiChevronRight, mdiEyeCheck, mdiEyeRemove } from "@mdi/js";
import {
    ConfigMenuGroup,
    ConfigMenuLayer,
} from "../../../../../../../../../store/system/systemTypes";
import {
    MapActionTypes,
    Visibility,
} from "../../../../../../../../../store/map/mapTypes";
import {
    setLayerVisibility,
    setHighlightedLayer,
    setGroupVisibility,
} from "../../../../../../../../../store/map/mapActions";
import { RootState } from "../../../../../../../../../store/store";
import { getStoreAtNamespaceKey } from "../../../../../../../../../store/storeSelectors";

import classes from "../LayersTab.module.css";
import { registerAnalyticsEvent } from "../../../../../../../../../store/matomo/matomoActions";
import { Collapse, Popover } from "@mantine/core";

interface OwnProps {
    group: ConfigMenuGroup;
    renderChildren: (
        children: (ConfigMenuGroup | ConfigMenuLayer)[],
    ) => ReactNode;
    initiallyExpanded: boolean;
}

interface StateProps {
    highlightedLayer: { layerName: string; sourceName: string } | null;
}

interface DispatchProps {
    setLayerVisibility: typeof setLayerVisibility;
    setGroupVisibility: typeof setGroupVisibility;
    setHighlightedLayer: typeof setHighlightedLayer;
    registerAnalyticsEvent: typeof registerAnalyticsEvent;
}
type GroupListItemProps = OwnProps & StateProps & DispatchProps;

interface GroupListItemState {
    isOpen: boolean;
}

export class GroupListItem extends React.Component<
    GroupListItemProps,
    GroupListItemState
> {
    constructor(props: GroupListItemProps) {
        super(props);
        this.state = {
            isOpen: props.initiallyExpanded,
        };
    }

    openGroup = () => {
        this.setState({
            isOpen: true,
        });
    };

    closeGroup = () => {
        this.setState({
            isOpen: false,
        });
    };

    toggleGroupOpen = () => {
        this.props.setHighlightedLayer(null);
        this.setState(
            (state) => {
                return { isOpen: !state.isOpen };
            },
            () => {
                let status = this.state.isOpen ? "opened" : "closed";
                this.props.registerAnalyticsEvent({
                    category: "Layers",
                    action:
                        this.props.group.groupName + " layer group " + status,
                });
            },
        );
    };

    setGroupVisibility(group: ConfigMenuGroup, status: Visibility) {
        this.props.setGroupVisibility({
            groupId: group.id,
            visibility: status,
        });
    }

    renderContextMenu(group: ConfigMenuGroup) {
        return (
            <>
                <div
                    onClick={(e) => {
                        this.setGroupVisibility(group, "visible");
                        e.stopPropagation();
                    }}
                    className={classes.ContextMenuItem}
                >
                    <Icon path={mdiEyeCheck} size={1} />
                    Show All Layers
                </div>
                <div
                    onClick={(e) => {
                        this.setGroupVisibility(group, "none");
                        e.stopPropagation();
                    }}
                    className={classes.ContextMenuItem}
                >
                    <Icon path={mdiEyeRemove} size={1} />
                    Hide All Layers
                </div>
            </>
        );
    }

    containsHighlightedLayer = (group: ConfigMenuGroup) => {
        return group.children.find((child) => {
            if (child.type === "layer") {
                return (
                    child.layerName ===
                        this.props.highlightedLayer?.layerName &&
                    child.layerSource ===
                        this.props.highlightedLayer?.sourceName
                );
            } else {
                this.containsHighlightedLayer(child);
            }
            return false;
        });
    };

    render() {
        const group = this.props.group;

        return (
            <div
                id={`touridLayerGroup${group.groupName}`}
                key={group.groupName}
                className={classes.Group}
            >
                <div className={classes.GroupHeader} onClick={this.toggleGroupOpen}>
                    <div className={classes.GroupName}>
                        <div
                            id={`touridLayerGroupOpen${group.groupName}`}
                            className={classes.GroupToggle}
                        >
                            <Icon
                                path={
                                    this.state.isOpen
                                        ? mdiChevronDown
                                        : mdiChevronRight
                                }
                            />
                        </div>
                        <span className={classes.GroupLabel}>
                            {group.groupName}
                        </span>
                    </div>
                    
                    <div className={classes.LayerMenu} onClick={(e) =>e.stopPropagation()}>
                        {!this.props.group.isInsights && ( 
                            <Popover 
                                position={"top-end"}
                                classNames={{
                                    dropdown: classes.ContextMenu,
                                    
                                }}
                            >
                                <Popover.Target>
                                    <Icon path={mdiDotsHorizontal}/>     
                                </Popover.Target>
                                
                                <Popover.Dropdown>
                                    {this.renderContextMenu(group)}  
                                </Popover.Dropdown>
                            </Popover>
                        )}
                    </div>
                </div>
                <div
                    className={classes.GroupBody}
                >
                    <Collapse in={this.state.isOpen}>
                        {this.props.renderChildren(group.children)}
                    </Collapse>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    highlightedLayer: getStoreAtNamespaceKey(state, "map").highlightedLayer,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, MapActionTypes>,
) => ({
    setLayerVisibility: bindActionCreators(setLayerVisibility, dispatch),
    setGroupVisibility: bindActionCreators(setGroupVisibility, dispatch),
    setHighlightedLayer: bindActionCreators(setHighlightedLayer, dispatch),
    registerAnalyticsEvent: bindActionCreators(
        registerAnalyticsEvent,
        dispatch,
    ),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(GroupListItem);
