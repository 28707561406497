import { PortfolioItem, CompanySchema } from "store/user/userTypes";
import {
    makeAPICall_DynamicUrl,
    makeAPICall_StaticUrl,
} from "../utils/APIHelpers";
import { genericAPIResponse, unsuccessfulAPIResponse } from "./genericResponse";
import { AccessInfo, AccessManagementUser } from "types/auth";

export interface GroupSchema {
    id: string;
    name: string;
}

export interface UserWithGroupDataSchema {
    id: string;
    group_id: string;
    group_name: string;
}

export interface NewGroupSchema {
    group: {
        id: string;
        name: string;
    };
    users: {
        id: string;
        first_name: string;
        last_name: string;
    }[];
    portfolios: {
        name: string;
        id: string;
    }[];
    access: {
        event_access: {
            id: string;
            event_types: string[];
            impact: string[];
            start_date: string;
            end_date: string;
            lloyds_cat_code: boolean;
            specific_event_names: string[];
            countries: string[];
            group_id: string;
        }[];
    };
}

export interface UserSchema {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    job_role: string;
    is_admin: boolean;
    company_id: string;
    company_name?: string;
    last_login_at?: string;
}

export interface EventAccessSchema {
    event_types: string[];
    impact: string[];
    start_date: string;
    end_date: string;
    lloyds_cat_code: boolean;
    specific_event_names: string[];
    countries: string[];
    group_id?: string;
}
export interface CreateEventAccessSchema {
    event_types: string[];
    impact: string[];
    start_date: string;
    end_date: string;
    lloyds_cat_code: boolean;
    specific_event_ids: string[];
    countries: string[];
    group_id?: string;
    id?: string;
}

export interface DisplayEventAccessSchema {
    event_types: string[];
    impact: string[];
    start_date: string;
    end_date: string;
    lloyds_cat_code: boolean;
    specific_event_ids?: string[];
    specific_event_names?: string[];
    countries: string[];
    group_id?: string;
    id?: string;
}

export interface GroupAccessSchema {
    id: string;
    event_types: string[];
    impact: string[];
    start_date: string;
    end_date: string;
    lloyds_cat_code: boolean;
    specific_event_names: string[];
    countries: string[];
    group_id: string;
}

export interface NewUserSchema {
    user: UserSchema;
    user_groups: {
        id: string;
        group_id: string;
        group_name: string;
    }[];
    groups_portfolios: {
        group: {
            id: string;
            name: string;
        };
        portfolios: {
            id: string;
            name: string;
        }[];
    }[];
    access: {
        event_access: GroupAccessSchema[];
    };
}

export interface PaginatedNewUserSchema {
    page: number;
    total: number;
    limit: number;
    success: boolean;
    message: string;
    data: NewUserSchema[];
}

export interface PaginatedNewGroupSchema {
    page: number;
    total: number;
    limit: number;
    success: boolean;
    message: string;
    data: NewGroupSchema[];
}

export interface PaginatedNewCompanySchema {
    page: number;
    total: number;
    limit: number;
    success: boolean;
    message: string;
    data: CompanySchema[];
}
export interface UserGroupConnectionSchema {
    id: string;
    user_id: string;
    group_id: string;
}

export interface PortfolioGroupConnectionSchema {
    id: string;
    portfolio_id: string;
    group_id: string;
}

export interface UserGroupSchema {
    user_id: string;
    group_id: string;
}

export interface UserGroupObjSchema {id: string}

export type UserUpdateSchema = Omit<
    UserSchema,
    "groups" | "user_preferences" | "user_access"
>;

export interface GroupAddUserSchema {
    group_id: string;
    user_id: string;
}

export interface FeedbackDetailsSchema {
    rating: number;
    details: string;
    type: string;
}
export interface FeedbackSchema extends FeedbackDetailsSchema {
    user_id: string;
}

export interface FeedbackSchemaResponse {
    success: boolean;
    message: string;
    data: FeedbackSchema;
}

export type UserSchemaBase = Omit<
    UserSchema,
    | "id"
    | "updated_at"
    | "created_at"
    | "groups"
    | "user_preferences"
    | "user_access"
>;

export type NewUserSchemaBase = {
    first_name: string;
    last_name: string;
    job_role: string;
    email: string;
    is_admin: boolean;
    company_id: string;
};

export type userCreateResponse = {
    success: boolean;
    message: string;
    data: {
        first_name: string;
        last_name: string;
        job_role: string;
        email: string;
        is_admin: boolean;
        id: string;
        created_at: number;
        okta_id: string;
        company_id: string;
    };
};

export type groupCreateResponse = {
    success: boolean;
    message: string;
    data: {
        name: string;
        industry: string | null;
        id: string;
    };
};

export type PortfolioSchema = {
    id: string;
    name: string;
};

export type PortfolioResponseSchema = {
    success: boolean;
    message: string;
    data: {
        name: string;
        id: string;
    };
};

export type UserPreferences = {
    tos: boolean;
    theme?: string;
};

interface UserMeEdit {
    first_name: string;
    last_name: string;
    last_login_at: string | null;
    job_role?: string;
}

export type PortfolioSchemaBase = Omit<PortfolioSchema, "id">;

export type GroupSchemaBase = Omit<GroupSchema, "id">;

export const groupGet = makeAPICall_StaticUrl<
    genericAPIResponse<GroupSchema[]>
>("/auth/groups/", "GET");

export const groupsGetAllAccess = makeAPICall_DynamicUrl<
    PaginatedNewGroupSchema | unsuccessfulAPIResponse,
    { search: string; page: number; limit: number },
    {}
>("/auth/groups/access/", "GET");

export const groupsGetEventAccessFromID = makeAPICall_DynamicUrl<
    genericAPIResponse<GroupAccessSchema[]>,
    { group_id_in: string },
    {}
>("/auth/event_access/");

export const userGet = makeAPICall_StaticUrl<genericAPIResponse<UserSchema[]>>(
    "/auth/users/",
    "GET",
);

export const usersGetAllAccess = makeAPICall_DynamicUrl<
    PaginatedNewUserSchema | unsuccessfulAPIResponse,
    { search: string; page: number; limit: number; order?: string },
    {}
>("/auth/users/access/", "GET");

export const getUserGroupConnections = makeAPICall_DynamicUrl<
    genericAPIResponse<UserGroupConnectionSchema[]>,
    { user_id?: string; group_id?: string },
    {}
>("/auth/users_groups/");

export const userMeUpdate = makeAPICall_StaticUrl<
    genericAPIResponse<{}>,
    UserMeEdit
>("/auth/users/me", "PATCH");

export const userCreate = makeAPICall_StaticUrl<
    userCreateResponse | unsuccessfulAPIResponse,
    NewUserSchemaBase
>("/auth/users/", "PUT");

export const userUpdate = makeAPICall_StaticUrl<
    UserUpdateSchema | unsuccessfulAPIResponse,
    UserUpdateSchema
>("/auth/users/", "PUT");

export const groupCreate = makeAPICall_StaticUrl<
    groupCreateResponse | unsuccessfulAPIResponse,
    GroupSchemaBase
>("/auth/groups/", "PUT");

export const groupAddUser = makeAPICall_StaticUrl<
    GroupAddUserSchema,
    GroupAddUserSchema
>("/auth/users_groups/", "POST");

export const companiesGet = makeAPICall_DynamicUrl<
    genericAPIResponse<CompanySchema[]>,
    { name?: string },
    {}
>("/auth/companies/", "GET");

export const companyCreateOrUpdate = makeAPICall_StaticUrl<
    genericAPIResponse<CompanySchema>,
    Omit<CompanySchema, "id">
>("/auth/companies/", "PUT");

export const feedbackCreate = makeAPICall_StaticUrl<
    FeedbackSchemaResponse,
    FeedbackDetailsSchema
>("/feedback/", "POST");

export const groupRemoveUser = makeAPICall_StaticUrl<
    GroupAddUserSchema,
    GroupAddUserSchema
>("/auth/users_groups/", "DELETE");

export const groupUpdate = makeAPICall_StaticUrl<GroupSchema, GroupSchema>(
    "/auth/groups/",
    "PUT",
);

export const portfolioCreate = makeAPICall_StaticUrl<
    PortfolioResponseSchema,
    PortfolioSchemaBase
>("/auth/portfolios/", "PUT");

export const portfolioGet = makeAPICall_StaticUrl<
    genericAPIResponse<PortfolioItem[]>
>("/portfolios/", "GET");

export const portfolioAllGet = makeAPICall_StaticUrl<
    genericAPIResponse<PortfolioItem[]>
>("/auth/portfolios/", "GET");

export const portfolioAddGroup = makeAPICall_StaticUrl<
    { id: string; group_id: string; portfolio_id: string },
    { group_id: string; portfolio_id: string }
>("/auth/groups_portfolios/", "POST");

export const portfolioCreateInsightsAccess = makeAPICall_StaticUrl<
    {
        id: string;
        portfolio_id: string;
        data_hosting: boolean;
        download_insights: boolean;
    },
    {
        portfolio_id: string;
        data_hosting: boolean;
        download_insights: boolean;
        group_id: string;
    }
>("/auth/insights_access/", "PUT");

export const portfolioRemoveGroup = makeAPICall_StaticUrl<{ id: string }>(
    "/auth/groups_portfolios/",
    "DELETE",
);

export const createUpdateEventAccess = makeAPICall_StaticUrl<
    {
        id: string;
        group_id: string;
        countries: string[];
        event_types: string[];
        impact: string[];
        start_date: string;
        end_date: string | null;
        lloyds_cat_code: boolean;
        specific_event_ids: string[];
    },
    CreateEventAccessSchema
>("/auth/event_access/", "PUT");

export const getPortfolioGroupConnections = makeAPICall_StaticUrl<
    genericAPIResponse<PortfolioGroupConnectionSchema[]>
>("/auth/groups_portfolios/");

export const getUserMe = makeAPICall_StaticUrl<
    genericAPIResponse<AccessManagementUser>
>("/auth/users/me", "GET");

export const getUserMeAccess = makeAPICall_StaticUrl<
    genericAPIResponse<AccessInfo>
>("/auth/users/me/access", "GET");

export const createUpdatePreferences = makeAPICall_StaticUrl<
    genericAPIResponse<String>,
    UserPreferences
>("/auth/users/me/preferences", "PUT");
