import Papa from "papaparse";
import { LoadInsightsPayload } from "./insightsTypes";
import numeral from "numeral";
import moment from "moment"
import { MOMENT_DATE_FORMAT } from "index";

export const EMAIL_VALIDATION_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const parseInsightsCSV = async (
    insightsData: LoadInsightsPayload["insightsData"],
    callback: (locationData: Papa.ParseResult<any>) => void,
) => {
    Papa.parse(insightsData!.details, {
        worker: true,
        delimiter: ",",
        header: true,
        complete: (results) => {
            // Below causes downstream issues in the mantine table.
            // empty row at the end of the csv is removed.
            if (
                results.errors.length > 0 &&
                results.errors[0].code === "TooFewFields"
            ) {
                results.data.pop();
            }

            callback(results);
        },
        dynamicTyping: true,
    });
};

const DO_NOT_FORMAT_CONDITIONS = ["id", "longitude", "latitude", "count", "code", "date", "contract", "year"]
const SHOW_TWO_DECIMALS_CONDITIONS = ["exchange_rate"]
const SHOW_FOUR_DECIMALS_CONDITIONS = ["share"]

export const formatValue = ( value: any, propertyName: string  ) => {
    let valToNumber = Number(value)
    let momentFormatStyle = (
        (valToNumber > 0 && valToNumber < 1) || SHOW_TWO_DECIMALS_CONDITIONS.includes(propertyName)
    ) ? "0,0.00" : "0,0"

    if (SHOW_FOUR_DECIMALS_CONDITIONS.includes(propertyName)) {
        momentFormatStyle = "0,0.0000"
    }

    if (propertyName.toLowerCase().includes("date")) {
        // Some Date columns include strings, so if not a valid number do not multiply by 1000 for UNIX conversion
        let convertedValue = Number.isNaN(valToNumber) ? value : value * 1000
        return moment(convertedValue).format(MOMENT_DATE_FORMAT);
    }
    
    if (Number.isNaN(valToNumber)) {
        return value;
    } else {
        if (DO_NOT_FORMAT_CONDITIONS.some(i => propertyName.toLowerCase().includes(i))) {
            return value
        } else {
            return numeral(valToNumber).format(momentFormatStyle)
        }    
    }
}
