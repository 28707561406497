import classes from "./InsightsLayerListItem.module.css";
import React, { Component, MouseEvent, ReactNode } from "react";
import { DropdownOption } from "../../../../../../../../_Library/Dropdown/Dropdown";
import Icon from "@mdi/react";
import {
    mdiChartBar,
    mdiCircle,
    mdiClose,
    mdiDotsHexagon,
    mdiFilterOff,
    mdiTable,
} from "@mdi/js";
import { getCssVar } from "../../../../../../../../../utils/CSSHelpers";
import ReactTooltip from "react-tooltip";
import {
    mdiChevronDown,
    mdiChevronUp,
    mdiDotsHorizontal,
    mdiEyeCheckOutline,
    mdiEyeOutline,
    mdiEyeRemoveOutline,
    mdiInformation,
} from "@mdi/js/commonjs/mdi";
import { RootState } from "../../../../../../../../../store/store";
import { getStoreAtNamespaceKey } from "../../../../../../../../../store/storeSelectors";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cx from "classnames";
import {
    setInsightsLayerVisibility,
    setInsightsLayerView,
    toggleDamageLabelFilter,
    toggleInsightsVisibility,
    setSelectedPortfolio,
    toggleCluster,
    setAssessmentType,
    clearAllFilters,
    clearFilteredIndices,
    setSelectedPeril,
    removeInsightsData,
} from "../../../../../../../../../store/insights/insightsActions";
import {
    InsightsState,
    InsightsActionTypes,
} from "../../../../../../../../../store/insights/insightsTypes";
import ButterflyLogo from "../../../../../../../../_Library/LoadingScreen/ButterflyLogo/ButterflyLogo";
import { setLegendPopup } from "../../../../../../../../../store/map/mapActions";

import {
    PortfolioItem,
    UserState,
} from "../../../../../../../../../store/user/userTypes";
import { withOktaAuth } from "@okta/okta-react";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import { registerAnalyticsEvent } from "store/matomo/matomoActions";
import { LocationInsightsWrapper } from "components/Pages/Report/DashboardComponents/Insights/LocationInsightsWrapper";
import { EventSchema } from "crud/eventsCRUD";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CapitaliseWord } from "utils/String";
import {
    AssessmentType,
    AssessmentTypes,
    PerilType,
} from "store/system/systemTypes";
import Legend from "../../../../Legend/Legend";
import { getPointLayerPaint } from "../../../../Mapping/MapLayers/PerilPaints";
import {
    LayerTypeRenderData,
    extractStylingPropertiesForIcons,
} from "utils/ExtractLegendData";
import PrettySelect from "components/_Library/Select/PrettySelect";
import Modal from "components/_Library/Modal/Modal";
import { shouldDisableDownloads } from "components/Pages/Report/DashboardComponents/Insights/utils";
import withAnalytics, {
    withAnalyticsProps,
} from "components/_Library/HOC/withAnalytics";
import withCurrentEvent, {
    withCurrentEventProps,
} from "components/_Library/HOC/withCurrentEvent";

interface StateProps {
    assessmentType: InsightsState["assessmentType"];
    insightsViewOn: InsightsState["insightsViewOn"];
    insightsVisible: InsightsState["insightsVisible"];
    clusterToggle: InsightsState["clusterToggle"];
    locationData: InsightsState["locationData"];
    insightsGeojson: InsightsState["insightsGeojson"];
    insightsFilters: InsightsState["insightsFilters"];
    selectedPeril: InsightsState["selectedPeril"];
    revisions: InsightsState["revisions"];

    user: UserState["user"];
    selectedPortfolio: PortfolioItem;
    insightsType: InsightsState["insightsType"];
    insightsData: InsightsState["insightsData"];
    isFetchingInsights: boolean;
    isInsightsLoaded: boolean;
    allEventData: EventSchema[];
    legendPopup: { layerName: string; sourceName: string } | null;
}

interface DispatchProps {
    setInsightsLayerVisibility: typeof setInsightsLayerVisibility;
    setAssessmentType: typeof setAssessmentType;
    setInsightsLayerView: typeof setInsightsLayerView;
    setLegendPopup: typeof setLegendPopup;
    toggleDamageLabelFilter: typeof toggleDamageLabelFilter;
    clearAllFilters: typeof clearAllFilters;
    clearFilteredIndices: typeof clearFilteredIndices;
    setInsightsVisibility: typeof toggleInsightsVisibility;
    setSelectedPortfolio: typeof setSelectedPortfolio;
    toggleCluster: typeof toggleCluster;
    registerAnalyticsEvent: typeof registerAnalyticsEvent;
    setPerilType: typeof setSelectedPeril;
    removeInsightsData: typeof removeInsightsData;
}

type Extensions = IOktaContext & withAnalyticsProps & withCurrentEventProps;
type InsightsLayersTabProps = DispatchProps &
    StateProps &
    Extensions &
    // eventId
    RouteComponentProps<{ id: string }>;

interface InsightsLayersTabState {
    showLegend: boolean;
    showToggleLayer: boolean;
    showTable: boolean;
    portfolioOptions: { id: string; title: string; defaultOption: boolean }[];
}
class InsightsLayerListItem extends Component<
    InsightsLayersTabProps,
    InsightsLayersTabState
> {
    state: InsightsLayersTabState = {
        showLegend: false,
        showToggleLayer: false,
        showTable: false,
        portfolioOptions: [],
    };

    componentDidUpdate(
        prevProps: Readonly<InsightsLayersTabProps>,
        prevState: Readonly<InsightsLayersTabState>,
        snapshot?: any,
    ) {
        if (
            prevProps.selectedPortfolio !== this.props.selectedPortfolio ||
            prevProps.user?.portfolios !== this.props.user?.portfolios
        ) {
            this.updatePortfolioOptions();
        }
    }

    componentDidMount(): void {
        this.updatePortfolioOptions();
    }

    addPortfolioQueryParam = () => {
        let url = new URL(window.location.href);
        window.history.replaceState({}, "", url.toString());
    };

    updatePortfolioOptions = () => {
        let userPortfolios = this.props.user?.portfolios ?? [];
        let portfolioQueryParam = new URL(
            window.location.href,
        ).searchParams.get("portfolio");
        if (
            !this.props.selectedPortfolio?.name &&
            portfolioQueryParam &&
            userPortfolios.length
        ) {
            // If we have a portfolio query param
            // and don't have a currently selected
            // portfolio, set the selected portfolio
            let portfolio = userPortfolios.find(
                (portfolio) => portfolio.name === portfolioQueryParam,
            );
            if (portfolio?.id) {
                this.props.setSelectedPortfolio({
                    selectedPortfolio: {
                        name: portfolio.name,
                        id: portfolio.id,
                    },
                });
            }
        }
        if (userPortfolios.length) {
            let newPortfolioQueryParam;
            let allOptions = userPortfolios.map(({ name, id }, idx) => {
                if (
                    name === this.props.selectedPortfolio?.name ||
                    (!this.props.selectedPortfolio?.name && idx === 0)
                ) {
                    newPortfolioQueryParam = name;
                }
                return {
                    id: id,
                    title: name,
                    defaultOption: name === this.props.selectedPortfolio?.name,
                };
            });
            this.setState({
                ...this.state,
                portfolioOptions: allOptions,
            });
            if (newPortfolioQueryParam) {
                this.addPortfolioQueryParam();
            }
        }
    };
    toggleLegend = () => {
        this.props.registerAnalyticsEvent({
            category: "Insights Interaction",
            action: this.state.showLegend
                ? "close assessment filters"
                : "open assessment filters",
        });
        this.setState({ showLegend: !this.state.showLegend });
    };

    setNewFilter = (label: string) => {
        this.props.toggleDamageLabelFilter({ damageLabel: label });
    };

    displayLegend = () => {
        return (
            <>
                {this.props.locationData ? (
                    <div className={cx(classes.LegendContainer)}>
                        <Legend
                            paint={getPointLayerPaint(
                                this.props.assessmentType,
                                this.props.selectedPeril!,
                            )}
                            layout={{}}
                            //@ts-ignore: string not assignable to never?
                            complexPaintProperties={["circle-color"]}
                            type={"circle"}
                            toggleLegendPopup={null}
                            layerName={"Assessment"}
                            legendPopup={this.props.legendPopup!}
                            parent="layerListItem"
                            filterClick={this.setNewFilter}
                            isFiltered={this.isFiltered()}
                            customFilter={{
                                identifier: "Assessment",
                                values: this.props.insightsFilters.damageLabels,
                            }}
                        />
                    </div>
                ) : null}
            </>
        );
    };

    setCurrentAssessment = (option: DropdownOption) => {
        this.props.setAssessmentType({
            assessmentType: option.id as AssessmentType,
        });
        this.props.setInsightsLayerVisibility({
            id: option.id,
            visibility: "visible",
        });
        this.props.clearAllFilters();
        this.props.registerAnalyticsEvent({
            category: "Insights Interaction",
            action: `switched insights layer to ${option.title}`,
        });
    };

    async setCurrentPortfolio(option: DropdownOption) {
        if (!(option.title && option.id)) return;
        if (this.props.currentEvent) {
            this.props.analytics.trackUserEvent({
                name: "portfolio_selected",
                payload: {
                    from: this.props.selectedPortfolio?.name,
                    to: option.title,
                    event_id: this.props.currentEvent.id,
                    event_name: this.props.currentEvent.name,
                },
            });
        }

        this.props.setSelectedPortfolio({
            selectedPortfolio: {
                name: option.title,
                id: option.id,
            },
        });

        // Update the query parameter in the URL
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: `?portfolio=${option.title}`,
        });
    }

    toggleShowToggleLayer = () => {
        this.setState({ showToggleLayer: !this.state.showToggleLayer });
    };

    renderPerilDropdown = () => {
        const availablePerils = this.props.revisions.map(
            (revision) => revision.peril,
        );

        if (availablePerils.length !== 0) {
            return (
                <div className={classes.DropdownContainer}>
                    <span>Peril</span>
                    <PrettySelect
                        data={availablePerils.map((peril) =>
                            CapitaliseWord(peril),
                        )}
                        defaultValue={
                            this.props.selectedPeril
                                ? CapitaliseWord(this.props.selectedPeril)
                                : availablePerils[0]
                        }
                        onClick={() => {
                            if (this.props.currentEvent) {
                                this.props.analytics.trackUserEvent({
                                    name: "peril_dropdown_clicked",
                                    payload: {
                                        source: "map",
                                        event_id: this.props.currentEvent.id,
                                        event_name:
                                            this.props.currentEvent.name,
                                    },
                                });
                            }
                        }}
                        onChange={(value: string) => {
                            if (this.props.currentEvent) {
                                this.props.analytics.trackUserEvent({
                                    name: "peril_selected",
                                    payload: {
                                        from: this.props
                                            .selectedPeril as PerilType,
                                        to: value.toLowerCase() as PerilType,
                                        event_id: this.props.currentEvent.id,
                                        event_name:
                                            this.props.currentEvent.name,
                                    },
                                });
                            }
                            this.props.setPerilType({
                                selectedPeril: value.toLowerCase() as PerilType,
                            });
                        }}
                        placeholder={"Select a peril"}
                    />
                </div>
            );
        } else {
            return null;
        }
    };

    renderAssessmentDropdown = () => {
        if (
            !this.props.isFetchingInsights &&
            this.props.isInsightsLoaded &&
            !this.props.insightsType
        ) {
            let assessments: AssessmentType[] = [...AssessmentTypes];

            assessments = assessments.filter(
                (assessment) =>
                    this.props.locationData?.meta.fields?.includes(
                        `${CapitaliseWord(assessment)} Layer Assessment`,
                    ),
            );

            const options = assessments.map((assessment) => {
                return {
                    id: assessment,
                    title: CapitaliseWord(assessment),
                    defaultOption: assessment === this.props.assessmentType,
                };
            });
            return (
                <div className={classes.DropdownContainer}>
                    <span>Assessment</span>
                    <PrettySelect
                        data={options.map((option) => option.title)}
                        defaultValue={
                            options.find(
                                (option) => option.defaultOption === true,
                            )?.title as string
                        }
                        onClick={() => {
                            if (this.props.currentEvent) {
                                this.props.analytics.trackUserEvent({
                                    name: "assessment_dropdown_clicked",
                                    payload: {
                                        event_id: this.props.currentEvent.id,
                                        event_name:
                                            this.props.currentEvent.name,
                                    },
                                });
                            }
                        }}
                        onChange={(value: string) => {
                            if (this.props.currentEvent) {
                                this.props.analytics.trackUserEvent({
                                    name: "assessment_selected",
                                    payload: {
                                        from: this.props
                                            .assessmentType as AssessmentType,
                                        to: value as AssessmentType,
                                        event_id: this.props.currentEvent.id,
                                        event_name:
                                            this.props.currentEvent.name,
                                    },
                                });
                            }

                            this.setCurrentAssessment(
                                options.find(
                                    (option) => option.title === value,
                                )!,
                            );
                        }}
                        placeholder={"Select an assessment"}
                    />
                </div>
            );
        }
        return null;
    };

    handleViewSelection = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.setInsightsLayerView({
            viewOn: (event.target as Element).innerHTML.toLowerCase() as
                | "left"
                | "right"
                | "both",
        });
        this.props.registerAnalyticsEvent({
            category: "Insights Interaction",
            action:
                "show on " +
                ((event.target as Element).innerHTML.toLowerCase() as
                    | "left"
                    | "right"
                    | "both"),
        });
        this.setState({
            showToggleLayer: false,
        });
    };

    renderContextMenu = () => {
        return (
            <div className={cx(classes.ContextMenuCont)}>
                <div
                    className={classes.ContextMenuItem}
                    id={"togglecluster_tutorial"}
                >
                    <button
                        className={classes.InsightsTableButton}
                        onClick={this.toggleCluster}
                    >
                        <Icon
                            path={
                                !this.props.toggleCluster
                                    ? mdiDotsHexagon
                                    : mdiCircle
                            }
                        />
                        <div className={classes.ContextMenuLabel}>
                            Toggle Cluster
                        </div>
                    </button>
                    <div
                        data-for={"ToggleModal"}
                        data-tip={`Loading times may be affected`}
                        onClick={this.toggleModal}
                    >
                        <Icon
                            path={mdiInformation}
                            className={classes.Icon}
                        />
                        <ReactTooltip
                            id={"ToggleModal"}
                            place={"top"}
                            effect={"solid"}
                            delayShow={300}
                        />
                    </div>
                </div>
                <div
                    className={classes.ContextMenuItem}
                    onClick={this.handleViewSelection}
                >
                    <Icon path={mdiEyeOutline} />
                    <span
                        className={cx({
                            [classes.Active]:
                                this.props.insightsViewOn === "left",
                        })}
                    >
                        Left
                    </span>
                    <span
                        className={cx({
                            [classes.Active]:
                                this.props.insightsViewOn === "both",
                        })}
                    >
                        Both
                    </span>
                    <span
                        className={cx({
                            [classes.Active]:
                                this.props.insightsViewOn === "right",
                        })}
                    >
                        Right
                    </span>
                </div>
            </div>
        );
    };

    renderLoader = () => {
        if (
            !this.props.isFetchingInsights &&
            this.props.isInsightsLoaded &&
            !this.props.insightsType
        ) {
            return;
        }

        let message: ReactNode | null;
        let showButterfly = false;

        if (this.props.user?.portfolios?.length === 0) {
            showButterfly = false;
            message = (
                <>
                    <p>
                        Analysis available after you share your portfolio
                        locations.
                    </p>
                    <p>Reach out to find out more.</p>
                </>
            );
        } else if (this.props.isFetchingInsights) {
            showButterfly = true;
            message = <>Fetching Insights data</>;
        } else if (!this.props.isInsightsLoaded) {
            showButterfly = true;
            message = <>Loading Insight layers</>;
        } else {
            message =
                this.props.insightsType === "noAffectedProperties" ? (
                    <>No exposed locations within the event footprint</>
                ) : (
                    <>Currently no Insights report exists for this event</>
                );
        }

        return (
            <div className={classes.InsightsLoader}>
                {showButterfly ? (
                    <ButterflyLogo animated height={15} />
                ) : (
                    <Icon
                        path={mdiInformation}
                        className={classes.Icon}
                    />
                )}
                <div className={classes.InsightsLoaderMessage}>{message}</div>
                {showButterfly && <ButterflyLogo animated height={15} />}
            </div>
        );
    };

    toggleModal = () => {
        if (!this.state.showTable && this.props.currentEvent) {
            this.props.analytics.trackUserEvent({
                name: "show_in_table_clicked",
                payload: {
                    event_id: this.props.currentEvent.id,
                    event_name: this.props.currentEvent.name,
                },
            });
        }
        this.props.registerAnalyticsEvent({
            category: "Insights Interaction",
            action: this.state.showTable
                ? "close data table"
                : "open data table",
        });
        this.setState({
            showTable: !this.state.showTable,
        });
    };

    renderTable = () => {
        return (
            <Modal closeModal={this.toggleModal} width={90} height={75}>
                <LocationInsightsWrapper
                    event={
                        this.props.allEventData.find(
                            (event: EventSchema) =>
                                event.id === this.props.match.params.id,
                        )!
                    }
                    insightsData={this.props.insightsData}
                    loadingInsights={this.props.isFetchingInsights}
                    // Padding means we need to give a bit of extra room
                    viewportHeightPct={68}
                    tableContainerMaxHeight={"90%"}
                    key={"insights-layer"}
                    disableDownloads={shouldDisableDownloads(
                        this.props.user,
                        this.props.selectedPortfolio?.id,
                    )}
                />
            </Modal>
        );
    };

    toggleInsightsVisibility = () => {
        this.props.registerAnalyticsEvent({
            category: "Insights Interaction",
            action: this.isVisible() ? "hide layer" : "show layer",
        });
        this.props.setInsightsVisibility();
    };

    renderPortfolioDropdown = () => {
        if (this.state.portfolioOptions.length) {
            return (
                <div className={classes.DropdownContainer}>
                    <span>Portfolio</span>
                    <PrettySelect
                        data={this.state.portfolioOptions.map(
                            (option) => option.title,
                        )}
                        defaultValue={
                            this.props.selectedPortfolio?.name ||
                            this.state.portfolioOptions[0].title
                        }
                        onClick={() => {
                            if (this.props.currentEvent) {
                                this.props.analytics.trackUserEvent({
                                    name: "portfolio_dropdown_clicked",
                                    payload: {
                                        source: "map",
                                        event_id: this.props.currentEvent.id,
                                        event_name:
                                            this.props.currentEvent.name,
                                    },
                                });
                            }
                        }}
                        onChange={(value: string) => {
                            this.setCurrentPortfolio(
                                this.state.portfolioOptions.find(
                                    (option) => option.title === value,
                                )!,
                            );
                        }}
                        placeholder={"Select a Portfolio"}
                        searchable
                    />
                </div>
            );
        }
    };

    renderPaints = () => {
        if (this.props.locationData) {
            let renderData = LayerTypeRenderData["circle"];
            let { standardProperties, propertiesForLegend } =
                extractStylingPropertiesForIcons(
                    renderData.paintToComponentProps,
                    getPointLayerPaint(
                        this.props.assessmentType,
                        this.props.selectedPeril!,
                    ),
                );

            let legendProperty = `${CapitaliseWord(
                this.props.assessmentType,
            )} Layer Assessment`;

            return (
                <div
                    className={classes.InsightFilterCont}
                    id={"tourId_PaintLegend"}
                >
                    {propertiesForLegend &&
                        Object.entries(propertiesForLegend[legendProperty]).map(
                            ([key, value], index) => {
                                return (
                                    <div
                                        key={key}
                                        className={cx(
                                            classes.InsightsFilterItem,
                                            {
                                                [classes.FullOpacity]:
                                                    this.props.insightsFilters
                                                        .damageLabels.length <
                                                        1 ||
                                                    this.props.insightsFilters.damageLabels.includes(
                                                        key,
                                                    ),
                                            },
                                        )}
                                        style={{
                                            // @ts-ignore
                                            backgroundColor: value.color,
                                            borderColor:
                                                standardProperties.borderColor
                                                    ? standardProperties.borderColor
                                                    : // @ts-ignore
                                                      value.borderColor,
                                            order: index,
                                        }}
                                        onClick={() => {
                                            this.setNewFilter(key);
                                        }}
                                        data-for={"InsightsFilterTooltip"}
                                        data-tip={`Filter: ${key}`}
                                    >
                                        <ReactTooltip
                                            id={"InsightsFilterTooltip"}
                                            place={"top"}
                                            effect={"solid"}
                                            delayShow={300}
                                        />
                                    </div>
                                );
                            },
                        )}
                </div>
            );
        }
    };

    isVisible = () => {
        return this.props.insightsVisible;
    };

    isFiltered = () => {
        const geojsonFiltered =
            this.props.locationData?.data.length !==
            this.props.insightsGeojson?.features?.length;
        return (
            !!this.props.insightsFilters?.damageLabels.length ||
            geojsonFiltered ||
            !!this.props.insightsFilters.contractIds?.length
        );
    };

    clearFilter = () => {
        if (
            this.props.insightsFilters?.damageLabels.length !== 0 ||
            this.props.insightsFilters?.contractIds?.length !== 0
        ) {
            this.props.clearAllFilters();
        }
        if (
            this.props.locationData?.data.length !==
            this.props.insightsGeojson?.features?.length
        ) {
            this.props.clearFilteredIndices();
        }
    };

    toggleCluster = () => {
        this.props.registerAnalyticsEvent({
            category: "Insights Interaction",
            action: this.props.clusterToggle
                ? "turned off cluster"
                : "turned on cluster",
        });
        this.props.toggleCluster();
    };

    render() {
        return (
            <div>
                <div className={classes.InsightHeader}>
                    <div className={classes.InsightHeading}>
                        <Icon path={mdiChartBar} />
                        Your Locations
                    </div>

                    {this.props.isInsightsLoaded &&
                        !this.props.insightsType && (
                            <div
                                className={classes.ContextMenu}
                                id={"contextmenu_tutorial"}
                                onClick={this.toggleShowToggleLayer}
                            >
                                <Icon
                                    path={
                                        this.state.showToggleLayer
                                            ? mdiClose
                                            : mdiDotsHorizontal
                                    }
                                    color={getCssVar(
                                        this.state.showToggleLayer
                                            ? "--highlight-color"
                                            : "--text-color",
                                    )}
                                />
                                {this.state.showToggleLayer &&
                                    this.renderContextMenu()}
                            </div>
                        )}
                </div>
                <div
                    className={classes.InsightBody}
                    id={"insightbody_tutorial"}
                >
                    {this.renderPortfolioDropdown()}
                    {this.renderPerilDropdown()}
                    {this.renderAssessmentDropdown()}
                    {!this.props.isFetchingInsights &&
                    this.props.isInsightsLoaded &&
                    !this.props.insightsType ? (
                        <div>
                            <div className={classes.InsightsBottom}>
                                <div className={classes.InsightsBottom_flex}>
                                    <div
                                        data-tip={"Toggle Layer Visibility"}
                                        data-for={"LayerVisibility"}
                                        className={classes.InsightsTableIcon}
                                        onClick={this.toggleInsightsVisibility}
                                    >
                                        <Icon
                                            path={
                                                this.isVisible()
                                                    ? mdiEyeCheckOutline
                                                    : mdiEyeRemoveOutline
                                            }
                                            color={
                                                this.isVisible()
                                                    ? getCssVar(
                                                          "--highlight-color",
                                                      )
                                                    : getCssVar("--text-color")
                                            }
                                        />
                                        <ReactTooltip
                                            id={"LayerVisibility"}
                                            place={"left"}
                                            effect={"solid"}
                                        />
                                    </div>
                                    <div
                                        className={classes.InsightsTableIcon}
                                        data-for={"ToggleModal"}
                                        data-tip={`Show in table`}
                                        onClick={this.toggleModal}
                                    >
                                        <Icon
                                            path={mdiTable}
                                            color={"var(--text-color)"}
                                        />
                                        <ReactTooltip
                                            id={"ToggleModal"}
                                            place={"top"}
                                            effect={"solid"}
                                            delayShow={300}
                                        />
                                    </div>
                                    <div
                                        id="tourid_RemoveInsightsFilter"
                                        className={classes.InsightsTableIcon}
                                        onClick={this.clearFilter}
                                        data-for={"ClearFilter"}
                                        data-tip={`Remove Filter`}
                                    >
                                        <Icon
                                            path={mdiFilterOff}
                                            color={getCssVar(
                                                this.isFiltered()
                                                    ? "--highlight-color"
                                                    : "--secondary-color",
                                            )}
                                        />
                                        <ReactTooltip
                                            id={"ClearFilter"}
                                            place={"top"}
                                            effect={"solid"}
                                            delayShow={300}
                                        />
                                    </div>
                                </div>
                                <div className={classes.InsightsBottom_flex}>
                                    {this.renderPaints()}
                                    <div
                                        className={classes.InsightsTableIcon}
                                        id={"insights_openLegend_tutorial"}
                                    >
                                        <ReactTooltip
                                            id={"LayerGroupToolTip"}
                                            place={"left"}
                                            effect={"solid"}
                                        />
                                        <div
                                            data-tip={"Open/Close Group"}
                                            data-for={"LayerGroupToolTip"}
                                            onClick={this.toggleLegend}
                                        >
                                            <Icon
                                                path={
                                                    this.state.showLegend
                                                        ? mdiChevronUp
                                                        : mdiChevronDown
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {this.state.showLegend && this.displayLegend()}
                            </div>
                            {this.state.showTable &&
                                this.props.locationData &&
                                this.renderTable()}
                        </div>
                    ) : (
                        this.renderLoader()
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    assessmentType: getStoreAtNamespaceKey(state, "insights").assessmentType,
    insightsViewOn: getStoreAtNamespaceKey(state, "insights").insightsViewOn,
    insightsVisible: getStoreAtNamespaceKey(state, "insights").insightsVisible,
    clusterToggle: getStoreAtNamespaceKey(state, "insights").clusterToggle,
    locationData: getStoreAtNamespaceKey(state, "insights").locationData,
    insightsGeojson: getStoreAtNamespaceKey(state, "insights").insightsGeojson,
    insightsFilters: getStoreAtNamespaceKey(state, "insights").insightsFilters,
    selectedPeril: getStoreAtNamespaceKey(state, "insights").selectedPeril,
    revisions: getStoreAtNamespaceKey(state, "insights").revisions,

    legendPopup: getStoreAtNamespaceKey(state, "map").legendPopup,
    user: getStoreAtNamespaceKey(state, "user").user,
    selectedPortfolio: getStoreAtNamespaceKey(state, "insights")
        .selectedPortfolio,
    insightsType: getStoreAtNamespaceKey(state, "insights").insightsType,
    isFetchingInsights: getStoreAtNamespaceKey(state, "insights")
        .isFetchingInsights,
    isInsightsLoaded: getStoreAtNamespaceKey(state, "insights")
        .isInsightsLoaded,
    insightsData: getStoreAtNamespaceKey(state, "insights").insightsData,
    allEventData: getStoreAtNamespaceKey(state, "service").eventData,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, InsightsActionTypes>,
) => ({
    setInsightsLayerVisibility: bindActionCreators(
        setInsightsLayerVisibility,
        dispatch,
    ),
    setAssessmentType: bindActionCreators(setAssessmentType, dispatch),
    setInsightsLayerView: bindActionCreators(setInsightsLayerView, dispatch),
    setLegendPopup: bindActionCreators(setLegendPopup, dispatch),
    toggleDamageLabelFilter: bindActionCreators(
        toggleDamageLabelFilter,
        dispatch,
    ),
    clearAllFilters: bindActionCreators(clearAllFilters, dispatch),
    clearFilteredIndices: bindActionCreators(clearFilteredIndices, dispatch),
    setInsightsVisibility: bindActionCreators(
        toggleInsightsVisibility,
        dispatch,
    ),
    setSelectedPortfolio: bindActionCreators(setSelectedPortfolio, dispatch),
    toggleCluster: bindActionCreators(toggleCluster, dispatch),
    registerAnalyticsEvent: bindActionCreators(
        registerAnalyticsEvent,
        dispatch,
    ),
    setPerilType: bindActionCreators(setSelectedPeril, dispatch),
    removeInsightsData: bindActionCreators(removeInsightsData, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    withRouter(
        withOktaAuth(withAnalytics(withCurrentEvent(InsightsLayerListItem))),
    ),
);
